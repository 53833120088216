import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  POST_LIST_SUCCESS,
  POST_LIST_FAIL,
  LIST_DYNAMIC,
  DELETE_LIST_DYNAMIC,
  ELEMENT_LIST,
} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { isLoggedIn: true, user, typeUser:'regular' }
  : { isLoggedIn: false, user: null };

export default function auth (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_SUCCESS:
      
      return {
        ...state,
        isLoggedIn: true,
        typeUser: "regular",
        user: payload.user,
        token: payload.token
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };
    case LOGIN_SUCCESS:
      const isSuperAdmin = payload?.user?.roles.includes("superadministrator") || false
      const isAdmin = payload?.user?.roles.includes("administrator") || false
      let type = 'regular'
      if(isAdmin) type = 'admin'
      if(isSuperAdmin) type = 'superadmin'
      return {
        ...state,
        isLoggedIn: true,
        typeUser: type,
        user: payload.user,
        token:payload.token
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        typeUser: null,
        user: null,
        token:undefined
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        typeUser: null,
        user: null,
        token:undefined
      };
    case POST_LIST_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
      };
    case POST_LIST_FAIL:
      return {
        ...state,
        isLoggedIn: false,
      };

    case LIST_DYNAMIC:
      return {
        ...state,
        isLoggedIn: true,
      };
    case DELETE_LIST_DYNAMIC:
      return {
        ...state,
        isLoggedIn: true,
      };
    case ELEMENT_LIST:
      return {
        ...state,
        isLoggedIn: true,
      };
    default:
      return state;
  }
}

export const getDataUser = (state) => {
  return state.auth.user
}
export const getTokenUser = (state) => {
  return state.auth.token
}
export const getTypeUser = (state) => {
  return state.auth.typeUser
}