import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import UserService from "../service/user.service";

import DeleteUser from "./modals/DeleteUser";
import EditUser from "./modals/EditUser";
import NewUser from "./modals/NewUser";
import Alert from "./Alert";
import AlertDialogSlide from "./modals/AlertDialogSlide";
import TableUser from "./TableUser";
import { postNewUser, logout } from "../actions/auth";
import ResetPassword from "./modals/ResetPassword";
import { Redirect } from "react-router-dom";
import { searchElementByName } from "../utils/searchElementByName";

const PageUsers = (props) => {
  const { isUserReg, isSuperUser } = props;

  const [modalData, setModalData] = useState({
    title: "",
    body: "",
    border: "",
  });

  const [globalState, setGlobalState] = useState({
    regular: [],
    admin: [],
    superadmin: [],
  });
  const [actualPages, setActualPages] = useState({
    regular: 1,
    admin: 1,
    superadmin: 1,
  });
  const [totalPages, setTotalPages] = useState({
    regular: 1,
    admin: 1,
    superadmin: 1,
  });
  const [transfor, setTransform] = useState(false);
  const [infoUser, setUserInfo] = useState({});
  const [userData, setUserData] = useState({});
  const [data, setData] = useState([]);

  const [classinput, setClass] = useState({});
  const [stateAlert, ] = useState({
    visible: false,
    code: 200,
    dataAlert: "",
  });

  const [refreshData, setrefreshData] = useState(false);

  const handleChangesPage = async (numberPage) => {
    let number_page = numberPage

    try {
      const copyGlobalState = { ...globalState };
      const copyActualPages = {...actualPages}
      const copyTotalPages = { ...totalPages };

      if (isUserReg) {
        if(!number_page) number_page = copyActualPages.regular
        const requestRegular = await UserService.getUsersRegBoard(number_page);
        copyGlobalState.regular = requestRegular.data.data;
        copyActualPages.regular = requestRegular.data.current_page;
        copyTotalPages.regular = requestRegular.data.last_page;
      } else if (isSuperUser) {
        if(!number_page) number_page = 1
        const requestSuperAdmin = await UserService.getUserSuperAdminBoard(number_page);
        //TODO: es necesario brindar el formato de  respuesta del back para obtenerla página actual y el maximo
        copyGlobalState.superadmin = requestSuperAdmin.data;
        copyActualPages.superadmin = number_page;
        copyTotalPages.superadmin = number_page;
      } else {
        if(!number_page) number_page = copyActualPages.admin
        const requestAdmin = await UserService.getUsersAdminBoard(number_page);
        copyGlobalState.admin = requestAdmin.data.data;
        copyActualPages.admin = requestAdmin.data.current_page;
        copyTotalPages.admin = requestAdmin.data.last_page;
      }
      setGlobalState(copyGlobalState);
      setActualPages(copyActualPages);
      setTotalPages(copyTotalPages);
    } catch (error) {
      const _content =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (_content === "Unauthenticated.") {
        logOut();
      }
    }
  };
  useEffect(() => {
    handleChangesPage()
  }, [refreshData, isUserReg, isSuperUser]);
  const [clickButton, setClick] = useState("btn-shadow btn btn-dark");

  const handleSearchDocument = (event, dataState) => {
    const value = searchElementByName(dataState,event.target.value);

    if (event.target.value === "") {
      setData([]);
    } else {
      setData(value);
    }
  };

  const [showEdiMod, setshowEdiMod] = useState(false);
  const [showDelMod, setshowDelMod] = useState(false);
  const [showNewMod, setshowNewMod] = useState(false);
  const [showChangeMod, setshowChangeMod] = useState(false);
  const [showAlertMod, setshowAlertMod] = useState(false);
  const [close, setClose] = useState(false);

  // Funcion para cambiar de estado de los modals
  const ModEdiCateSate = () => setshowEdiMod(!showEdiMod);
  const ModDelCateSate = () => setshowDelMod(!showDelMod);
  const ModNewCateSate = () => setshowNewMod(!showNewMod);
  const ModChangeCateSate = () => setshowChangeMod(!showChangeMod);
  const ModAlertMod = () => setshowAlertMod(!showAlertMod);

  const OpenModalNew = (e) => {
    e.preventDefault();
    ModNewCateSate();
  };

  const handleModalDelete = (e, name, id) => {
    e.preventDefault();
    setClass({ name: name, user: name, id__user: id });
    ModDelCateSate();
  };

  const OpenModalChange = (e, name, email, id) => {
    e.preventDefault();
    setClass({ name: name, user: email, id__user: id });
    ModChangeCateSate();
  };
  const OpenModalEdit = (e, user, isView) => {
    e.preventDefault();
    ModEdiCateSate();
    setClass({
      name: user.name,
      user: user.name,
      id__user: user.id,
      isView: isView,
    });
    setUserData(user);
  };

  const handleClickSearch = () => {
    setTransform(true);
    setClick("btn-shadow btn btn-dark btn-border");
  };

  const handleClickClose = () => {
    setTransform(false);
    setClass({ butn: "btn-shadow btn btn-dark" });
  };

  const handleChange = (e) => {
    setUserInfo({
      ...infoUser,
      [e.target.name]: e.target.value,
    });
  };

  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
    setClose(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if(
      infoUser.password!==infoUser.password2 ||
      !infoUser.edit_badge
    ) {
      setModalData({
        title: "Lo sentimos",
        body: "Error en los datos.\n",
        border: "bg-danger",
      });
      ModAlertMod();
      return
    }
    dispatch(
      postNewUser(
        infoUser.name,
        infoUser.email,
        infoUser.password,
        infoUser.password2,
        infoUser.document_id,
        infoUser.edit_badge,
        
      )
    )
      .then((response) => {

        setModalData({
          title: "Felicidades",
          body: response.data.message,
          border: "bg-success",
        });
        ModAlertMod();
        handleChangesPage(1)
      })
      .catch((error) => {

        console.error(error);
        setModalData({
          title: "Lo sentimos",
          body: "No se realizo la creación del usuario.\n",
          border: "bg-danger",
        });
        ModAlertMod();
      });
    ModNewCateSate();
  };

  const OpenModalAlert = () => {
    setModalData({
      title: "STOP",
      body: "Este es tu usuario así que no lo puedes eliminar",
      border: "bg-warning",
    });
    ModAlertMod();
  };
  return (
    <div className="panel__control">
      {close && <Redirect to="/login/admin" />}
      <main className="main__fields">
        <div className="header__field">
          <div className="image__list">
            <i className="fas fa-users"></i>
          </div>
          <div className="text__information">
            <h4>
              {!isUserReg && !isSuperUser ? "Usuarios Administradores" : ""}
              {isUserReg ? "Usuarios Regulares" : ""}
              {isSuperUser ? "Usuarios Superadministradores" : ""}
            </h4>
          </div>
          <div className="create__or__search active">
            <div className="input-search d-flex">
              {transfor ? (
                <>
                  <button
                    className={clickButton}
                    onClick={(e) => handleClickClose()}
                  >
                    <i className="fas fa-times"></i>
                  </button>
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Buscar usuarios"
                    onChange={(e) => {
                      if (isUserReg) {
                        handleSearchDocument(e, globalState.regular);
                      } else if (isSuperUser) {
                        handleSearchDocument(e, globalState.superadmin);
                      } else {
                        handleSearchDocument(e, globalState.admin);
                      }
                    }}
                  />
                </>
              ) : (
                <button
                  className={clickButton}
                  onClick={(e) => handleClickSearch()}
                >
                  <i className="fas fa-search"></i>
                </button>
              )}
            </div>
            <Button onClick={(e) => OpenModalNew(e)} className="btn-search">
              <i className="fas fa-user-plus"></i>
              <span className="ml-3">Nuevo Usuario</span>
            </Button>
          </div>
        </div>
        <div className="list__users">
          {isUserReg ? (
            <>
              <TableUser
                isUserRegTable
                data={data}
                state={globalState.regular}
                currentPage={actualPages.regular}
                totalPage={totalPages.regular}
                OpenModalChange={OpenModalChange}
                OpenModalEdit={OpenModalEdit}
                OpenModalDelete={handleModalDelete}
                handleChangesPage={handleChangesPage}
                setrefreshData={setrefreshData}
              />
            </>
          ) : (
            ""
          )}
          {isSuperUser ? (
            <>
              <TableUser
                data={data}
                state={globalState.superadmin}
                currentPage={actualPages.superadmin}
                totalPage={totalPages.superadmin}
                OpenModalEdit={OpenModalEdit}
                OpenModalDelete={handleModalDelete}
                handleChangesPage={handleChangesPage}
                setrefreshData={setrefreshData}
                OpenModalAlert={OpenModalAlert}
              />
            </>
          ) : (
            ""
          )}
          {!isSuperUser && !isUserReg ? (
            <>
              <TableUser
                data={data}
                state={globalState.admin}
                currentPage={actualPages.admin}
                totalPage={totalPages.admin}
                OpenModalEdit={OpenModalEdit}
                OpenModalDelete={handleModalDelete}
                handleChangesPage={handleChangesPage}
                setrefreshData={setrefreshData}
                OpenModalAlert={OpenModalAlert}
              />
            </>
          ) : (
            ""
          )}
        </div>
        <Alert
          visible={stateAlert.visible}
          code={stateAlert.code}
          dataAlert={stateAlert.dataAlert}
        />
      </main>
      {showNewMod && <NewUser
        data={infoUser}
        showNewMod={showNewMod}
        setshowNewMod={setshowNewMod}
        ModNewCateSate={ModNewCateSate}
        setrefreshData={setrefreshData}
        refreshData={refreshData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />}
      <DeleteUser
        showDelMod={showDelMod}
        setshowDelMod={setshowDelMod}
        title={"Eliminar usuario: " + classinput.user}
        message={
          "¿Estas seguro de eliminar la cuenta de " + classinput.name + "?"
        }
        userDelete={classinput.id__user}
        refreshData={refreshData}
        setrefreshData={setrefreshData}
        ModAlertMod={ModAlertMod}
        setModalData={setModalData}
      />
      <ResetPassword
        showChangeMod={showChangeMod}
        setshowChangeMod={setshowChangeMod}
        title={"Resetear contraseña usuario: " + classinput.user}
        message={
          "¿Estas seguro de resetear la contraseña " + classinput.name + "?"
        }
        userId={classinput.id__user}
        setrefreshData={setrefreshData}
        ModAlertMod={ModAlertMod}
        setModalData={setModalData}
      />
      <EditUser
        title={classinput.name}
        showEdiMod={showEdiMod}
        setshowEdiMod={setshowEdiMod}
        setrefreshData={setrefreshData}
        refreshData={refreshData}
        user={userData}
        isView={classinput.isView}
        ModAlertMod={ModAlertMod}
        setModalData={setModalData}
      />
      <AlertDialogSlide
        modaldata={modalData}
        show={showAlertMod}
        onHide={() => ModAlertMod()}
      />
    </div>
  );
};

export default PageUsers;
