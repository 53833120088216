import { CCol, CFormCheck, CFormInput, CRow } from "@coreui/react";
import Multiselect from "multiselect-react-dropdown";
import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import CountryPhoneCode from "../../../utils/CountryPhoneCode.json";
import { IMaskMixin } from "react-imask";

const CFormInputWithMask = IMaskMixin(({ inputRef, ...props }) => (
  <CFormInput
    {...props}
    ref={inputRef} // bind internal input
  />
));

const SignerInput = ({
  onRemove,
  onSave,
  onChange = () => {},
  advanced,
  disabled,
  id = "controlId",
  values,
}) => {
  const handleChange = (e) => {
    const dataToSave = {};
    dataToSave[e.target.name] = e.target.value;
    onChange(dataToSave);
  };
  const handleChangeCheck = (e) => {
    
    const { checked, name } = e.target;
    const dataToSave = {};
    dataToSave[name] = checked;
    onChange(dataToSave);
  };
  return (
    <CRow className="signer_input_group">
      <CCol>
        {onRemove ? (
          <button
            disabled={disabled}
            className="btn_firm"
            type="button"
            onClick={onRemove}
          >
            <i className="fas fa-minus-circle"></i> Quitar
          </button>
        ) : (
          ""
        )}
        <FloatingLabel
          controlId={`${id}_name`}
          label={"Nombre"}
          className="mb-3 signer_input"
        >
          <Form.Control
            disabled={disabled}
            onChange={handleChange}
            value={values.name}
            name="name"
            type={"text"}
            placeholder={"Nombre"}
          />
        </FloatingLabel>
        {advanced ? (
          <>
            <FloatingLabel
              controlId={`${id}_email`}
              label={"Email (opcional)"}
              className="mb-3 signer_input"
            >
              <Form.Control
                disabled={disabled}
                onChange={handleChange}
                value={values.email}
                name="email"
                type={"email"}
                placeholder={"Email (opcional)"}
              />
            </FloatingLabel>
            <CRow className="mb-3">
              <CCol
                md="5"
                lg="4"
                className="pe-0 ch-100 input-group-multiselect"
              >
                <Multiselect
                
                  selectionLimit={1}
                  keepSearchTerm={false}
                  className={`h-100 ch-100 signer_input ${values.phone_country? 'hide_input_select':''}`}
                  hidePlaceholder
                  placeholder="País"
                  emptyRecordMsg="¿?"
                  options={CountryPhoneCode.map((Country) => {
                    return {
                      name: `${Country.name} (${Country.dial_code})`,
                      id: `${Country.dial_code}`,
                    };
                  })} // Options to display in the dropdown
                  selectedValues={
                    values.phone_country
                      ? [
                          {
                            name: values.phone_country,
                            id: values.phone_country,
                          },
                        ]
                      : []
                  } // Preselected value to persist in dropdown
                  onSelect={(e) => {
                    const optionListCont = document.querySelector(
                      ".optionListContainer.displayBlock"
                    );

                    optionListCont.className =
                      "optionListContainer displayNone";
                    document.body.click();
                    document.body.focus();
                    handleChange({
                      target: {
                        value: e[0].id,
                        name: "phone_country",
                      },
                    });
                  }} // Function will trigger on select event
                  onRemove={(e) => {
                    handleChange({
                      target: {
                        value: "",
                        name: "phone_country",
                      },
                    });
                  }} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                />
              </CCol>
              <CCol>
                <FloatingLabel
                  controlId={`${id}_phone_number`}
                  label={"Celular"}
                  className=""
                >
                  <CFormInputWithMask
                    disabled={disabled}
                    className="signer_input"
                    mask={"00000000000"}
                    onChange={handleChange}
                    value={values.phone_number}
                    name="phone_number"
                    type={"text"}
                    placeholder={"Celular"}
                  />
                </FloatingLabel>
              </CCol>
            </CRow>
          </>
        ) : (
          ""
        )}
      </CCol>
      <CCol>
        <CRow>
          {advanced ? (
            <>
              <CCol>
                <CFormCheck
                  disabled={disabled}
                  onChange={handleChangeCheck}
                  name="send_automatic_email"
                  label="Enviar Correo Automáticamente"
                  checked={Boolean(values.send_automatic_email)}
                />
                <CFormCheck
                  disabled={disabled}
                  onChange={handleChangeCheck}
                  name="send_automatic_whatsapp"
                  label="Enviar WhatsApp Automáticamente"
                  checked={Boolean(values.send_automatic_whatsapp)}
                />
              </CCol>
              <CCol>
                <CFormCheck
                  disabled={disabled}
                  onChange={handleChangeCheck}
                  name="require_selfie_photo"
                  label="Selfie"
                  checked={Boolean(values.require_selfie_photo)}
                />
                <CFormCheck
                  disabled={disabled}
                  onChange={handleChangeCheck}
                  name="require_document_photo"
                  label="Documento de identidad"
                  checked={Boolean(values.require_document_photo)}
                />
              </CCol>
            </>
          ) : (
            ""
          )}
          {onSave && (
            <CCol xs="12">
              <button
                disabled={disabled}
                onClick={onSave}
                type="button"
                className="next_step_btn"
              >
                Guardar
              </button>
            </CCol>
          )}
        </CRow>
      </CCol>
    </CRow>
  );
};

export default SignerInput;
