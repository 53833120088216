import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { logout } from "../actions/auth";

import "../assets/styles/components/Footer.scss";
import ImageDer from "../assets/static/vector-fondo2.png";
import ImageIzq from "../assets/static/IzqPanel.png";
import Perfil from "../assets/static/perfil.png";
import Folder from "../assets/static/folder.png";
import Documento from "../assets/static/documento.png";
import Soporte from "../assets/static/soporte.png";
import Close from "../assets/static/Close.png";
import { Redirect } from "react-router-dom";
import Face from "../assets/static/redes-face.png";
import Linkedin from "../assets/static/redes-linkedin.png";
import Twitter from "../assets/static/redes-twitter.png";

export default function Footer(props) {
  const { isHome, isLogged, isProfile, showModal, showSignModal=()=>{} } = props;

  const [close, setClose] = useState(false);
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
    setClose(true);
  };

  return (
    <>
      {close && <Redirect to="/login" />}
      {isHome && (
        <footer className="footer__login img__home">
          <img src={ImageDer} alt="" />
        </footer>
      )}
      {isProfile && (
        <footer className="footer footer__profile">
          <div className="profiles__panel">
            <Link to="/profile">
              <figure className="img__profiles">
                <img src={Face} alt="" />
              </figure>
            </Link>
            <Link
              to={{
                pathname: "/profile",
                state: { id: 4 },
              }}
            >
              <figure className="img__profiles">
                <img src={Linkedin} alt="" />
              </figure>
            </Link>
            <figure className="img__profiles">
              <img src={Twitter} alt="" />
            </figure>
            <figure className="img__profiles" onClick={() => logOut()}>
              <img src={Close} alt="" />
            </figure>
          </div>
          <div>Todos los derechos reservados Yo Litigo.com</div>
          <a
            href="https://wa.me/573507928813"
            className="whatsapp_float"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-whatsapp whatsapp-icon"></i>
          </a>
        </footer>
      )}

      {isLogged && (
        <footer className={showModal ? "footer modal_show_footer" : "footer"}>
          <div className="img__derFooter">
            <img src={ImageIzq} alt="" />
          </div>
          <div className="profiles__panel">
            <Link to="/profile">
              <figure className="img__profiles">
                <img src={Perfil} alt="" />
                <span>Mi perfil</span>
              </figure>
            </Link>
            <Link
              to={{
                pathname: "/profile",
                state: { documents: true },
              }}
            >
              <figure className="img__profiles">
                <img src={Folder} alt="" />
                <span>Mis documentos</span>
              </figure>
            </Link>
            <Link to="/panel">
              <figure className="img__profiles">
                <img src={Documento} alt="" />
                <span>Nuevo documento</span>
              </figure>
            </Link>
              <figure className="img__profiles" onClick={() => {showSignModal(prev => {
                return !prev
              })}}>
                <i className="fas fa-pen-fancy icon__profiles"></i>
                <span>Firmar documento</span>
              </figure>
            <figure className="img__profiles" onClick={() => logOut()}>
              <img src={Close} alt="" />
              <span>Cerrar sesión</span>
            </figure>
          </div>
          <div className="soporte__panel">
            <img src={Soporte} alt="" />
          </div>
        </footer>
      )}
    </>
  );
}
