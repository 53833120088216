import {
  CCol,
  CForm,
  CModal,
  CModalBody,
  CModalTitle,
  CRow,
} from "@coreui/react";
import { ReactComponent as CloseSVG } from "../../../assets/svg/cross.svg";
import React, { useEffect, useState } from "react";
import useArray from "../../../utils/CustomHooks/useArray";
import { isUndefined } from "lodash";
import { Spinner } from "reactstrap";
import useToast from "../../../utils/CustomHooks/useToast";
import DropzoneInput from "../../FormComponents/DropzoneInput";
import { FormConfigFirm, FormSigners } from ".";
import signService from "../../../service/sign.service";
import { formatNumbersDot } from "../../../utils/TextUtils";
import { useSelector } from "react-redux";
import { getDataUser } from "../../../reducers/auth";
import MercadoPago from "../MercadoPago";
import Routes from "../../../routes/Routes";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const steps = [0, 1, 2, 3];
const emptySigner = {
  name: "",
  email: "",
  auth_mode: "assinaturaTela",
  send_automatic_email: true,
  send_automatic_whatsapp: false,
  phone_country: "",
  phone_number: "",
  lock_email: false,
  blank_email: false,
  hide_email: false,
  lock_phone: false,
  blank_phone: false,
  hide_phone: false,
  lock_name: false,
  require_selfie_photo: false,
  require_document_photo: false,
  selfie_validation_type: "liveness-document-match",
};

function DropDocument({
  price = '',
  documentHook,
}) {
  const [pdfFile, setPdfFile] = documentHook;
  const handleDropDocument = async (acceptedFiles = []) => {
    
    if (acceptedFiles.length !== 1) return;

    const notDocx = acceptedFiles.findIndex((e) => {
      return (
        e.type !==
          "application/pdf" 
      );
    });
    if (notDocx >= 0) return;
    setPdfFile(acceptedFiles[0])
  };
  return (
    <>
      <CRow>
        <CCol className="form_sign_header">
          <h5>Añada el documento a firmar</h5>
        </CCol>
      </CRow>
      <CRow className="pe-5">
        <CCol xs='12'>
          <p>La firma del documento tendrá un precio de ${formatNumbersDot(parseFloat(price),2)}</p>
        </CCol>
        <CCol >
          <DropzoneInput
            loaded={Boolean(pdfFile?.name)}
            className={`mt-2 ${pdfFile?.name ? "dropzone_file_loaded" : ""}`}
            onDrop={handleDropDocument}
          >
            <p>{pdfFile?.name || "Importar documento o arrastrar aquí"}</p>
          </DropzoneInput>
        </CCol>
      </CRow>
    </>
  );
}
//<CFormCheck onChange={handleChangeCheck} name='reminder_every_n_days' checked={Boolean(values.reminder_every_n_days)} label="Activar recordatorios" />

function ExternalDocumentSignModal({
  showHook = [],
  dataHook = [],
}) {
  const [show, setShow] = showHook;
  const [data, setData] = dataHook;
  const dataUser = useSelector(getDataUser);
  const [pdfFile, setPdfFile] = useState(null);
  const [externalSignSettings, setExternalSignSettings] = useState({})
  const [loading, setLoading] = useState(true);
  const [advancedInputs, setAdvancedInputs] = useState(true);
  const [configurationOptionValues, setConfigurationOptionValues] = useState({
    reminder_active: false,
    reminder_every_n_days: 1,
    allow_refuse_signature: false,
    date_limit_active: false,
    date_limit_to_sign: null,
  });
  const [firebaseData, setFirebaseData] = useState(null);
  const [actualPage, setActualPage] = useState(0);
  const {
    array: signers_array,
    //set :  signers_set,
    push: signers_push,
    //filter :  signers_filter,
    update: signers_update,
    remove: signers_remove,
    //clear :  signers_clear
  } = useArray([{ ...emptySigner }]);
  const history = useHistory()
  const addToast = useToast();

  useEffect(() => {
    const requestExternalSign= async () => {
        const externalSignSettings = await signService.requestExternalSignSettings()
        setLoading(false);
        setExternalSignSettings(externalSignSettings)
    }
    requestExternalSign()
    return () => {
      setFirebaseData(null);
      setActualPage(0);
      setLoading(true);
    };
  }, [data]);

  const handleEditSigner = ({
    index,
    name,
    email,
    phone_country,
    phone_number,
    send_automatic_email,
    send_automatic_whatsapp,
    require_selfie_photo,
    require_document_photo,
  }) => {
    const copySigner = { ...signers_array[index] };
    if (!isUndefined(name)) copySigner.name = name;
    if (!isUndefined(email)) copySigner.email = email;
    if (!isUndefined(phone_country)) copySigner.phone_country = phone_country;
    if (!isUndefined(phone_number)) copySigner.phone_number = phone_number;
    if (!isUndefined(send_automatic_email))
      copySigner.send_automatic_email = send_automatic_email;
    if (!isUndefined(send_automatic_whatsapp))
      copySigner.send_automatic_whatsapp = send_automatic_whatsapp;
    if (!isUndefined(require_selfie_photo))
      copySigner.require_selfie_photo = require_selfie_photo;
    if (!isUndefined(require_document_photo))
      copySigner.require_document_photo = require_document_photo;
    signers_update(index, copySigner);
  };
  const handleEditConfig = ({
    reminder_active,
    date_limit_active,
    reminder_every_n_days,
    allow_refuse_signature,
    date_limit_to_sign,
  }) => {
    const toChangeValues = {};
    if (!isUndefined(reminder_active))
      toChangeValues.reminder_active = reminder_active;
    if (!isUndefined(date_limit_active))
      toChangeValues.date_limit_active = date_limit_active;
    if (!isUndefined(reminder_every_n_days))
      toChangeValues.reminder_every_n_days = reminder_every_n_days;
    if (!isUndefined(allow_refuse_signature))
      toChangeValues.allow_refuse_signature = allow_refuse_signature;
    if (!isUndefined(date_limit_to_sign))
      toChangeValues.date_limit_to_sign = date_limit_to_sign;
    setConfigurationOptionValues((prev) => ({ ...prev, ...toChangeValues }));
  };

  const handleRemove = (index) => {
    signers_remove(index);
  };

  const handleFreeFirm = async (firebaseOrder) => {
    try {
      const payer = {
        payer: {
          email: dataUser.email
        },
        transaction_details: '',
        taxes_amount: 0,
        payment_method: null,
      }
      const order = await signService.createExternalFirmDocument(dataUser.id + '/'+ firebaseOrder?.firebase_id,'free',payer)
      history.push(`${Routes.profile}?externalDocument=${order.id}`,)
    } catch (error) {
      setLoading(false);
      if (error.msg || error.message) {
        addToast({
          title: error.msg || error.message,
          mode: "danger",
        });
      }
      console.error(error);
    }
  };

  const createFirm = async () => {
    //const link = await userService.getDocumentStorageBlob(data.document_number);
    let response;
    debugger
    if (signers_array.filter((e) => Boolean(e.name)).length === 0) {
      return Promise.reject({
        msg: "falta agregar firmantes",
      });
    }
    const configOptions = {
      reminder_every_n_days: parseInt(
        configurationOptionValues.reminder_every_n_days
      ),
      allow_refuse_signature:
        configurationOptionValues.allow_refuse_signature,
      date_limit_to_sign: configurationOptionValues.date_limit_to_sign,
    };
    if (!configurationOptionValues.reminder_active) {
      configOptions.reminder_every_n_days = 0;
    }
    if (!configurationOptionValues.date_limit_active) {
      configOptions.date_limit_to_sign = null;
    }
    const signersToSend = signers_array.map((signer) => {
      if (advancedInputs) {
        return { ...signer };
      }
      return {
        name: signer.name,
      };
    });

    try {
      response = await signService.createExternalFirmTemporalDocument({
        document: pdfFile,
        transaction_amount:(externalSignSettings.free )? 0: externalSignSettings.price,
        user:dataUser,
        options:configOptions,
        signers:signersToSend

      });
      setFirebaseData(response);
      if(externalSignSettings.free) handleFreeFirm(response)
    } catch (error) {
      return Promise.reject(error);
    }
  };
  const handleNextPage = async () => {
    try {
      if (actualPage === steps.length - 2) {
        setLoading(true);
        await createFirm();
        setLoading(false);
      }
      if (actualPage >= steps.length - 1) {
        setShow(false);
        return;
      }
      setActualPage((actual) => {
        return actual + 1;
      });
    } catch (error) {
      setLoading(false);
      if (error.msg || error.message) {
        addToast({
          title: error.msg || error.message,
          mode: "danger",
        });
      }
      console.error(error);
    }
  };

  return (
    <CModal
      size="lg"
      className={`modal_firm_document `}
      visible={show}
      onClose={() => {
        setShow(false);
        setActualPage(0)
      }}
      backdrop="static"
    >
      <CModalBody>
        <CRow>
          <CCol className="modal_firm_document_preview" xs="12">
            <CRow>
              <CCol className="form_sign_header">
                <CModalTitle className="text-center">{data.name}</CModalTitle>
                <div className="stepper_indicator">
                  {steps.map((pge, ind) => {
                    return (
                      <div
                        style={{
                          width: `${100 / steps.length}%`,
                        }}
                        className={actualPage >= ind ? "active" : ""}
                        key={`pge_${ind}`}
                      ></div>
                    );
                  })}
                </div>
              </CCol>
              <CCol className="modal_create_document_preview" xs="auto">
                <button
                  onClick={() => {
                    setShow(false);
                  }}
                  type="button"
                  className="close_modal_stepper"
                >
                  <CloseSVG />
                </button>
              </CCol>
            </CRow>
          </CCol>
          <CCol className="modal_firm_document_form my-3" md="12">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "250px" }}
              >
                <Spinner size={"xl"} animation="border" variant="primary" />
              </div>
            ) : (
              <CForm>
                {actualPage === 0 ? (
                  <DropDocument
                  price={(externalSignSettings.free )? '0': externalSignSettings.price}
                  documentHook={[pdfFile, setPdfFile]}
                  />
                ) : actualPage === 1 ? (
                  <FormSigners
                    signers={signers_array}
                    advanceInputsHook={[advancedInputs, setAdvancedInputs]}
                    onAddSigner={signers_push}
                    onRemoveSigner={handleRemove}
                    onChangeSigner={handleEditSigner}
                  />
                ) : actualPage === 2 ? (
                  <FormConfigFirm
                    values={configurationOptionValues}
                    onChange={handleEditConfig}
                  />
                ) : (actualPage === 3 && !externalSignSettings.free) ? (
                  <div className="pe-5">
                    <MercadoPago
                    onCancel={()=>
                      setShow(false)}
                    paymentData={{
                      unit_price: parseFloat((externalSignSettings.free )? 0: externalSignSettings.price),
                      title: pdfFile.name,
                    }}
                    documentFirebaseId={firebaseData?.firebase_id}
                  ></MercadoPago>
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ height: "250px" }}
                  >
                    <Spinner size={"xl"} animation="border" variant="primary" />
                  </div>
                )}
              </CForm>
            )}
          </CCol>

         {(actualPage !== 3) ? <div className="d-flex justify-content-end" >
            <>
              {actualPage > 0 && actualPage < steps.length - 1 && (
                <button
                  type="button"
                  className="prev_step_btn"
                  onClick={() => {
                    if (actualPage === 0) return;
                    setActualPage((actual) => {
                      return actual - 1;
                    });
                  }}
                >
                  Regresar
                </button>
              )}
              <button
              disabled={!pdfFile}
                type="button"
                onClick={handleNextPage}
                className="next_step_btn"
              >
                {actualPage < steps.length - 1 ? "Siguiente" : "Finalizar"}
              </button>
            </>
          </div>:''}
        </CRow>
      </CModalBody>
    </CModal>
  );
}

export default ExternalDocumentSignModal;
