import React, { useEffect, useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { logout } from "../../actions/auth";
import { Redirect } from "react-router-dom";
import UserService from "../../service/user.service";

const EditUser = (props) => {
  const {
    title,
    showEdiMod,
    setshowEdiMod,
    user,
    isView,
    setrefreshData,
    refreshData,
    ModAlertMod,
    setModalData,
  } = props;
  const ModEdiCateSate = () => setshowEdiMod(!showEdiMod);
  const [data, setData] = useState({
    name: "",
    email: "",
    active: false,
  });
  const [close, setClose] = useState(false);
  const [rolesSelect, setRoles] = useState("administrator");

  const rolesData = JSON.stringify(user.roles);
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
    setClose(true);
  };

  useEffect(() => {

    if (user) {
      setData({
        name: user.name,
        email: user.email,
        active: `${user.active}`,
      });
    } else {
      setData({ name: "", email: "", active: false });
    }

    return () => {};
  }, [user]);

  const handleChange = (e) => {
    const { name, value = "" } = e.target;

    setData((prev) => {
      const newDta = { ...prev, [name]: value };
      return newDta;
    });
  };
  const handleChangeRole = (e) => {
    setRoles(e.target.value);
  };

  const handleSubmit = (id, select) => {
    const datatoSend = { ...data,
      roles: [select]
    };
    UserService.rolesUser(id, datatoSend).then(
      (response) => {
        setModalData({
          title: "Muy bien",
          body: response.data.message,
          border: "bg-success",
        });

        ModEdiCateSate();
        setrefreshData(!refreshData);
        ModAlertMod();
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (_content === "Unauthenticated") {
          logOut();
        } else {
          setModalData({
            title: "Lo sentimos",
            body: "No se pudo editar el usuario.\n" + error.data,
            border: "bg-danger",
          });
          ModEdiCateSate();
          ModAlertMod();
        }
      }
    );
  };
  return (
    <Modal show={showEdiMod} id="ModalEdit" onHide={ModEdiCateSate}>
      {close && <Redirect to="/login/admin" />}
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Form role="form">
        <Modal.Body>
          <FormGroup className="fields__new">
            <Label>Nombre completo</Label>
            <Form.Control
              type="text"
              name="name"
              onChange={handleChange}
              value={data.name}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="fields__new">
            <Label>email</Label>
            <Form.Control
              type="email"
              name="email"
              onChange={handleChange}
              value={data.email}
            ></Form.Control>
          </FormGroup>
          <FormGroup className="fields__new">
            <Label>Estado</Label>
            <Form.Control
              as="select"
              name="active"
              onChange={handleChange}
              value={data.active}
            >
              <option value={'true'}>Activo</option>
              <option value={'false'}>Inactivo</option>
            </Form.Control>
          </FormGroup>
          <FormGroup className="fields__new">
            <Label>Rol</Label>
            {isView ? (
              <Form.Control
                type="text"
                name="document_id"
                value={rolesData}
                disabled
              ></Form.Control>
            ) : (
              <Form.Control
                as="select"
                name="edit_badge"
                onChange={handleChangeRole}
                required
              >
                <option value="0" disabled>
                  Seleciona un opcion
                </option>
                <option value="administrator">Administrador</option>
                <option value="superadministrator">Superadministrator</option>
              </Form.Control>
            )}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button color="danger" onClick={ModEdiCateSate}>
            Cerrar
          </Button>
          {isView ? (
            ""
          ) : (
            <Button
              color="success"
              onClick={(e) => handleSubmit(user.id, rolesSelect)}
            >
              Guardar Cambios
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditUser;
