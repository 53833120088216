/* eslint-disable react-hooks/exhaustive-deps */
import {
  CButton,
  CCard,
  CCardBody,
  CCardTitle,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CRow,
  CTooltip,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import DoubleRadiusInput from "./FormComponents/DoubleRadiusInput";
import DropzoneInput from "./FormComponents/DropzoneInput";
import Multiselect from "multiselect-react-dropdown";
import mammoth from "mammoth/mammoth.browser";
import UserAdmin from "../service/user.service";
import { getListDinamic } from "../actions/auth";
import { useDispatch } from "react-redux";
import DraggableVariables from "./FormComponents/DraggableVariables";
import Select from "react-select";
import CurrencyFormat from "react-currency-format";

function CreateTemplate({
  documentEdit,
  onDocumentChanged = () => {},
  invalid,
  categories = [],
  documentVars,
  setDocumentVars,
  documentList,
  setDocumentList,
  documentDta = {},
  selectedCategories = [],
  setSelectedCategories,
  arrayHookStepper = {},
  dataFormHook = [],
  totalVarsListHook = [],
}) {
  //distpach
  const dispatch = useDispatch();
  const [dataForm, setDataForm] = dataFormHook;

  const {
    array: stepperPages,
    //set: setStepperPages,
    remove: removeStepperPages,
    push: addStepperPage,
    update: updateStepperPage,
    clear: clearStepperPages,
    /*
    filter,
    */
  } = arrayHookStepper;
  const [loading, setLoading] = useState(false);
  const [totalVarsList, setTotalVarsList] = totalVarsListHook;
  const [listDinamicsItems, setListDinamicsItems] = useState([]);

  useEffect(() => {
    let stateArray = [];
    dispatch(getListDinamic()).then((response) => {
      for (const key in response) {
        stateArray.push({ value: key, label: response[key].name });
      }
      setListDinamicsItems(stateArray);
    });
    return () => {};
  }, []);
  const handleValidation = async (event) => {
    event.preventDefault();

    clearStepperPages();
    setLoading(true);
    try {
      const { data } = await UserAdmin.validationDocument(documentDta.doc);

      
      console.log(documentVars, data.vars, documentList, data.lists);
      const newDocumentVars = data.vars.reduce((acc, curr) => {
        const newAcc = { ...acc };
        if (documentVars[curr]) newAcc[curr] = documentVars[curr];
        return newAcc;
      }, {});
      const newDocumentList = data.lists.reduce((acc, curr) => {
        const newAcc = { ...acc };
        if (documentList[curr]) newAcc[curr] = documentList[curr];
        return newAcc;
      }, {});

      addStepperPage({
        vars: data.vars,
        lists: data.lists,
      });
      setDocumentVars(newDocumentVars);
      setDocumentList(newDocumentList);
      setTotalVarsList(data.vars?.length + data.lists?.length);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    let { name, value, type, checked } = e.target;
    
    if (
      name === "need_firm" ||
      name === "supervise" ||
      name === "percentage_check"
    ) {
      value = value === "true";
    }
    if (
      dataForm.percentage_check &&
      name === "supervise_price" &&
      parseInt(value.replace(/,/g, "")) > 100
    )
      value = 100;
    if (type === "checkbox") {
      value = checked;
    }
    setDataForm((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  const handleChangeMultiSelect = (e) => {
    
    setSelectedCategories(e);
  };

  const handleChangeVars = (e) => {
    setDocumentVars((prevDta) => {
      return { ...prevDta, [e.target.name]: e.target.value };
    });
  };
  const handleChangeList = ({ value, name, multi, label }) => {
    setDocumentList((prevDta) => {
      const oldDta = prevDta[name]
        ? { ...prevDta[name] }
        : {
            multi: false,
          };
      if (value) oldDta.value = value;
      if (label) oldDta.label = label;
      if (multi !== undefined) oldDta.multi = multi;
      return { ...prevDta, [name]: oldDta };
    });
  };

  const handleDropDocument = async (acceptedFiles = []) => {
    if (acceptedFiles.length !== 1) return;

    const notDocx = acceptedFiles.findIndex((e) => {
      return (
        e.type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
        e.type !== "application/msword"
      );
    });
    if (notDocx >= 0) return;

    try {
      var reader = new FileReader();
      reader.onloadend = function (event) {
        var arrayBuffer = reader.result;
        var options = {
          styleMap: [
            "p:aligned(center) => h2",
            "p[style-name='Heading'] => h1.fresh",
            "p[style-name='Section Title'] => h2:fresh",
            "p[style-name='Subsection Title'] => h3:fresh",
          ],
          ignoreEmptyParagraphs: false,
          includeEmbeddedStyleMap : false,
          includeDefaultStyleMap : false
        };
        
        mammoth
          .convertToHtml({ arrayBuffer: arrayBuffer }, options)
          .then(function (resultObject) {
            let formatedResult = `${resultObject.value}`
                      .replace(new RegExp('<p></p>','g'),'<p class="divider" ></p>')
                      .replace(new RegExp('<p>','g'),'<p style="margin: 0;">')
            onDocumentChanged({
              name: acceptedFiles[0].name,
              doc: formatedResult,
            });
          })
          .catch((err) => console.error(err));
        /*
        mammoth.extractRawText({arrayBuffer: arrayBuffer}).then(function (resultObject) {
          
          console .log(resultObject.value)
        })
  
        mammoth.convertToMarkdown({arrayBuffer: arrayBuffer}).then(function (resultObject) {
          
          console .log(resultObject.value)
        })
        */
      };
      reader.readAsArrayBuffer(acceptedFiles[0]);
    } catch (error) {
      console.error(error);
    }
  };


  //draggerActions
  const handleNewPage = () => {
    addStepperPage({
      vars: [],
      lists: [],
    });
  };
  /**
   * Update
   * @param {*} pages
   */
  const handleDragVar = ({ new_page, old_page }) => {
    if (old_page) {
      updateStepperPage(old_page.index, old_page.data);
    }
    if (new_page) {
      updateStepperPage(new_page.index, new_page.data);
    }
  };
  const handleMetaDataPage = ({ index, data }) => {
    updateStepperPage(index, data);
  };
  const handleRemoveDataPage = (index) => {
    removeStepperPages(index);
  };
  const handleToggleObligatory = ({ variable_name, remove_index }) => {
    setDataForm((prev) => {
      const optionals = [...prev.optionals_variables_list];
      if (remove_index >= 0) optionals.splice(remove_index, 1);
      if (variable_name) optionals.push(variable_name);
      return { ...prev, optionals_variables_list: optionals };
    });
  };
  return (
    <CCard className="mx-3 modal_create_Template">
      <CCardTitle>Información Documentos</CCardTitle>
      <CForm className="px-1 px-md-2 px-lg-4">
        <CRow>
          <CCol lg="6">
            <CFormLabel>Nombre del documento</CFormLabel>
            <CInputGroup
              className={`${invalid && !dataForm.name ? "invalid" : ""}`}
            >
              <CInputGroupText id="basic-addon1">@</CInputGroupText>
              <CFormInput
                invalid={invalid && !dataForm.name}
                aria-label="name"
                name="name"
                value={dataForm.name}
                onChange={handleChange}
                aria-describedby="basic-addon1"
              />
            </CInputGroup>
          </CCol>
          <CCol className="mt-2" lg="3">
            <DoubleRadiusInput
              title="¿El documento requiere Firma?"
              onChange={handleChange}
              name="need_firm"
              value={`${dataForm.need_firm}`}
            />
          </CCol>
          <CCol className="mt-2" lg="3">
            <CFormLabel>Valor a pagar</CFormLabel>
            <CInputGroup
              className={
                (!dataForm.need_firm || dataForm.free_template)
                  ? "disabled"
                  : invalid && !dataForm?.firm_price
                  ? "invalid"
                  : ""
              }
            >
              <CInputGroupText id="basic-addon1">@</CInputGroupText>
              <CurrencyFormat
                className="form-control"
                allowNegative={false}
                thousandSeparator={true}
                disabled={!dataForm.need_firm || dataForm.free_template}
                aria-label="firm_price"
                name="firm_price"
                value={!dataForm.free_template ? dataForm.firm_price : 0}
                onChange={handleChange}
                aria-describedby="basic-addon1"
              />
            </CInputGroup>
          </CCol>
          <CCol className="mt-2" md="6">
            <CFormLabel>IVA</CFormLabel>
            <CInputGroup
              className={`${invalid && !dataForm.IVA ? "invalid" : ""}`}
            >
              <CInputGroupText id="basic-addon1">@</CInputGroupText>
              <CFormInput
                invalid={invalid && !dataForm.IVA}
                aria-label="IVA"
                name="IVA"
                type="number"
                min="0"
                max="50"
                value={dataForm.IVA}
                onChange={handleChange}
                aria-describedby="basic-addon1"
              />
            </CInputGroup>
          </CCol>
          <CCol className="mt-2" md="3">
            <DoubleRadiusInput
              title="¿Necesitas Supervisor?"
              onChange={handleChange}
              name="supervise"
              value={`${dataForm.supervise}`}
            />
          </CCol>
          <CCol className="mt-2" md="3">
            <DoubleRadiusInput
              disabled={!dataForm.supervise}
              title="Valor de la revisión"
              trueLabel="Porcentual"
              falseLabel="Efectivo"
              onChange={handleChange}
              name="percentage_check"
              value={`${dataForm.percentage_check}`}
            />
          </CCol>
          <CCol
            className="d-flex flex-column justify-content-center align-items-center"
            xs="auto"
            md="1"
          >
            <CFormLabel>Gratuito</CFormLabel>
            <CFormCheck
              onChange={handleChange}
              checked={dataForm.free_template}
              name="free_template"
              style={{
                position: "relative",
                margin: 0,
                marginTop: ".2em",
              }}
              id={`freeTemplate`}
            />
          </CCol>
          <CCol className="mt-2" md="5">
            <CFormLabel>Precio (min 10,000)</CFormLabel>
            <CInputGroup
              className={`${
                dataForm.free_template
                  ? "disabled"
                  : invalid &&
                    parseFloat(`${dataForm.price}`.replace(/,/g, "") || 0) <
                      10000
                  ? "invalid"
                  : ""
              }`}
            >
              <CInputGroupText id="basic-addon1">@</CInputGroupText>
              <CurrencyFormat
                disabled={dataForm.free_template}
                className="form-control"
                allowNegative={false}
                thousandSeparator={true}
                aria-label="price"
                name="price"
                value={!dataForm.free_template ? dataForm.price : 0}
                onChange={handleChange}
                aria-describedby="basic-addon1"
              />
            </CInputGroup>
          </CCol>
          <CCol className="mt-2" md="6">
            <CFormLabel>Valor total a pagar al supervisor</CFormLabel>
            <CInputGroup
              className={`${
                !dataForm.supervise || dataForm.free_template
                  ? "disabled"
                  : invalid && !dataForm.supervise_price
                  ? "invalid"
                  : ""
              }`}
            >
              <CInputGroupText id="basic-addon1">
                {dataForm.percentage_check ? "%" : "$"}
              </CInputGroupText>
              <CurrencyFormat
                className="form-control"
                allowNegative={false}
                thousandSeparator={true}
                disabled={!dataForm.supervise || dataForm.free_template}
                aria-label="supervise_price"
                name="supervise_price"
                value={!dataForm.free_template ? dataForm.supervise_price : 0}
                min={0}
                max={!dataForm.percentage_check ? 100 : undefined}
                onChange={handleChange}
                aria-describedby="basic-addon1"
              />
            </CInputGroup>
          </CCol>
          <CCol className="my-2">
            <CFormLabel>Seleccionar Categorías</CFormLabel>
            <CInputGroup
              className={`input-group-multiselect ${
                invalid && selectedCategories.length === 0 ? "invalid" : ""
              }`}
            >
              <CInputGroupText id="basic-addon1">@</CInputGroupText>
              <Multiselect
                className="w-100"
                emptyRecordMsg="No hay categorías Disponibles"
                options={categories.map((category) => {
                  return {
                    name: category.name,
                    id: category.id,
                  };
                })} // Options to display in the dropdown
                selectedValues={selectedCategories} // Preselected value to persist in dropdown
                onSelect={handleChangeMultiSelect} // Function will trigger on select event
                onRemove={handleChangeMultiSelect} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />
            </CInputGroup>
          </CCol>
          <CCol xs="12">
            <DropzoneInput
              loaded={Boolean(documentDta.name)}
              className={`mt-2 ${documentDta.name ? "dropzone_file_loaded" : ""}`}
              onDrop={handleDropDocument}
            >
              <p>{documentDta.name || "Importar documento o arrastrar aquí"}</p>
            </DropzoneInput>
            <CFormLabel>Formato soportado: .docx</CFormLabel>
          </CCol>
          <CCol className="my-2 d-flex justify-content-center" xs="12">
            <CButton
              onClick={handleValidation}
              className="validate-document-button"
            >
              Validar Documento
            </CButton>
          </CCol>
          <CCol lg="6">
            <CCard className="card_variable_cont">
              <CCardTitle>Variables Creadas</CCardTitle>
              <CCardBody>
                {!loading && (
                  <DraggableVariables
                    invalid={invalid}
                    onRemovePage={handleRemoveDataPage}
                    maxPages={totalVarsList}
                    onDragEnd={handleDragVar}
                    pagesVars={stepperPages}
                    onNewPage={handleNewPage}
                    onEditPageMetaDta={handleMetaDataPage}
                    noItemsMsg="Sin variables asignadas a esta página"
                  >
                    {(variable, i) => {
                      const optionalIndex =
                        dataForm.optionals_variables_list.findIndex(
                          (opt) => opt === variable
                        );
                      return (
                        <CRow
                          className={`d-flex variable_draggable ${
                            invalid && !documentVars?.[variable]
                              ? "invalid"
                              : ""
                          }`}
                          key={`variable_${i}`}
                        >
                          <CCol className="p-0" xs="auto">
                            <i className="fa fa-bars"></i>
                          </CCol>
                          <CCol
                            className="d-flex flex-column justify-content-center align-items-center"
                            xs="auto"
                          >
                            <p
                              style={{
                                lineHeight: "1",
                                fontSize: "10px",
                              }}
                              className="m-0"
                            >
                              {optionalIndex >= 0 ? (
                                <>Opcional</>
                              ) : (
                                <>Obligatorio</>
                              )}
                            </p>
                            <CFormCheck
                              onChange={(e) => {
                                handleToggleObligatory({
                                  remove_index: optionalIndex,
                                  variable_name:
                                    optionalIndex >= 0 ? undefined : variable,
                                });
                              }}
                              checked={optionalIndex >= 0}
                              style={{
                                position: "relative",
                                margin: 0,
                                marginTop: ".2em",
                              }}
                              id={`${variable}_varObligatorio`}
                            />
                          </CCol>
                          <CCol md="7" className="variable_name_cont">
                            <CTooltip content={variable}>
                              <p className="m-0">{variable}</p>
                            </CTooltip>
                          </CCol>
                          <CCol className="variable_input_cont">
                            <CFormLabel>Variables</CFormLabel>
                            <CFormSelect
                              aria-label="Default select example"
                              onChange={handleChangeVars}
                              value={documentVars?.[variable] || ""}
                              name={variable}
                              options={[
                                "Elija una opción",
                                { label: "Texto", value: "String" },
                                { label: "Numero", value: "Integer" },
                                {
                                  label: "Numero con decimales",
                                  value: "Float",
                                },
                                { label: "Fecha", value: "Date" },
                                { label: "Texto Largo", value: "TextArea" },
                                { label: "Si o No", value: "Checkbox" },
                              ]}
                            />
                          </CCol>
                        </CRow>
                      );
                    }}
                  </DraggableVariables>
                )}
              </CCardBody>
            </CCard>
          </CCol>
          <CCol lg="6">
            <CCard className="card_variable_cont">
              <CCardTitle>Listas dinámicas</CCardTitle>
              <CCardBody>
                {!loading && (
                  <DraggableVariables
                    invalid={invalid}
                    type="lists"
                    maxPages={totalVarsList}
                    droppableId="listDraggables"
                    onDragEnd={handleDragVar}
                    pagesVars={stepperPages}
                    onNewPage={handleNewPage}
                    onEditPageMetaDta={handleMetaDataPage}
                    noItemsMsg="Sin Listas asignadas a esta página"
                    onRemovePage={handleRemoveDataPage}
                  >
                    {(list, i) => {
                      const colourStyles = {
                        control: (styles) => ({
                          ...styles,
                          backgroundColor: "white",
                        }),
                        option: (
                          styles,
                          { data, isDisabled, isFocused, isSelected }
                        ) => {
                          return {
                            ...styles,
                            backgroundColor: isDisabled
                              ? undefined
                              : isSelected
                              ? "rgb(226, 34, 226)"
                              : isFocused
                              ? "rgba(226, 34, 226,.5)"
                              : undefined,
                            color: isDisabled
                              ? "#ccc"
                              : isSelected || isFocused
                              ? "white"
                              : "black",
                            cursor: isDisabled ? "not-allowed" : "default",

                            ":active": {
                              ...styles[":active"],
                              backgroundColor: !isDisabled
                                ? isSelected
                                  ? data.color
                                  : "rgba(226, 34, 226,1)"
                                : undefined,
                            },
                          };
                        },
                      };
                      const optionalIndex =
                        dataForm.optionals_variables_list.findIndex(
                          (opt) => opt === list
                        );
                      return (
                        <CRow
                          className={`d-flex variable_draggable ${
                            invalid && !documentList?.[list] ? "invalid" : ""
                          }`}
                          key={`list_${i}`}
                        >
                          <CCol className="p-0" xs="auto">
                            <i className="fa fa-bars"></i>
                          </CCol>
                          <CCol
                            className="d-flex flex-column justify-content-center align-items-center"
                            xs="auto"
                          >
                            <p
                              style={{
                                lineHeight: "1",
                                fontSize: "10px",
                              }}
                              className="m-0"
                            >
                              {optionalIndex >= 0 ? (
                                <>Opcional</>
                              ) : (
                                <>Obligatorio</>
                              )}
                            </p>
                            <CFormCheck
                              onChange={(e) => {
                                handleToggleObligatory({
                                  remove_index: optionalIndex,
                                  variable_name:
                                    optionalIndex >= 0 ? undefined : list,
                                });
                              }}
                              checked={optionalIndex >= 0}
                              style={{
                                position: "relative",
                                margin: 0,
                                marginTop: ".2em",
                              }}
                              id={`${list}_multiCheck`}
                            />
                          </CCol>
                          <CCol md="6" className="variable_name_cont">
                            <CTooltip content={list}>
                              <p className="m-0">{list}</p>
                            </CTooltip>
                          </CCol>
                          <CCol className="variable_input_cont">
                            <CFormLabel>Variables</CFormLabel>
                            {listDinamicsItems.length > 0 && (
                              <Select
                                className="search_select"
                                id="select-id"
                                value={documentList?.[list]}
                                onChange={(e) => {
                                  handleChangeList({
                                    value: e.value,
                                    name: list,
                                    label: e.label,
                                  });
                                }}
                                styles={colourStyles}
                                options={listDinamicsItems}
                              />
                            )}
                          </CCol>
                          <CCol
                            className="d-flex flex-column justify-content-center align-items-center"
                            xs="auto"
                          >
                            <p
                              style={{
                                lineHeight: "1",
                                fontSize: "10px",
                              }}
                              className="m-0"
                            >
                              Opción{" "}
                              <span className="d-block">
                                {documentList?.[list]?.multi ? (
                                  <>multiple</>
                                ) : (
                                  <>simple</>
                                )}
                              </span>
                            </p>
                            <CFormCheck
                              onChange={(e) => {
                                handleChangeList({
                                  multi: e.target.checked,
                                  name: list,
                                });
                              }}
                              checked={documentList?.[list]?.multi}
                              style={{
                                position: "relative",
                                margin: 0,
                                marginTop: ".2em",
                              }}
                              id={`${list}_multiCheck`}
                            />
                          </CCol>
                        </CRow>
                      );
                    }}
                  </DraggableVariables>
                )}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CForm>
    </CCard>
  );
}

export default React.memo(CreateTemplate);
