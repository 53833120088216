import React, { useRef, useState } from "react";
import moment from "moment";
import { Button } from "reactstrap";

import "../assets/styles/components/Profile.scss";
import DeleteDoc from "./modals/DeleteDoc";
import AttachmentsDoc from "./modals/AttachmentsDoc";

import UserService from "../service/user.service";
import PreviewDocument from "./modals/PreviewDocument";
import FirmDocumentModal from "./modals/FirmDocumentModal";

const DocumentProfile = ({
  state,
  setrefreshData,
  refreshData,
  ModAlertMod,
  setModalData,
  handleModal,
  setTitle,
  setData,
  modalShow,
}) => {
   const firmModal = useState(false);
  const [showDelMod, setshowDelMod] = useState(false);
  const [infoDoc, setInfoDoc] = useState({
    id: "",
  });
  const [firmDoc, setFirmDoc] = useState({
    id: "",
  });
  const refPage = useRef(null);
  const [attachments, setAttachments] = useState(false);
  const [previewDocumentModal, setPreviewDocumentModal] = useState(false);
  const [selectDoc, setSelectDoc] = useState();
  const [listAttachments, setListAttachments] = useState([]);

  // const ModEdiCateSate = () => setshowEdiMod(!showEdiMod);
  const ModDelCateSate = () => setshowDelMod(!showDelMod);

  const showAttachments = async (doc) => {
    const documentFile = await UserService.getDocumentFirebase(
      doc.firebase_document_id
    );
    const attachment =
      documentFile?.attachments?.length > 0
        ? documentFile.attachments.map((it) => ({
            filename: it,
            created_at: "",
          }))
        : [];
    

    setListAttachments(attachment);
    setSelectDoc(doc);
    setAttachments(true);
  };

  // const OpenModalEdit = (e, dataCatg) => {
  //   e.preventDefault();
  //   setShowData(dataCatg);
  //   ModEdiCateSate();
  // };
  /* for type-script
interface PdfProps {
  dataUrl: string;
  pageSize?: {
    width: number;
    height: number;
  };
  pageOrientation?: string;
  contentSize?: {
    width: number;
    height: number;
  };
  pageMargins?: [number, number];
}
  const createPDF = (doc) => {
    // const pdfDoc = new jsPDF("p", "mm", "a4");
    // const pdfTable = document.getElementById('divToPrint');
    //html to pdf format

    const RATE = 2.83464566929;
    var html = htmlToPdfmake("<p".concat(doc.document));
    const date = new Date();
    const templateId = doc.template_id;
    const documentDefinition = {
      content: html,
      pageMargins: [20 * RATE, 20 * RATE],
      info: {
        title: `${date.toLocaleDateString()}${templateId}.pdf`,
      },
      defaultFileName: `${date.toLocaleDateString()}${templateId}.pdf`,
    };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    pdfMake
      .createPdf(documentDefinition)
      .download(`${date.toLocaleDateString()}${templateId}.pdf`);

    pdfMake.createPdf(documentDefinition).open();
    // pdfDoc.html(doc.document,{
    //     callback: function(pdf){
    //       pdf.save(doc.name);
    //     },
    //     x: 10,
    //     y: 10,
    //     width: 1110,
    //     windowWidth:1300
    //   });
  };
  */
  const OpenModalDelete = (doc) => {
    setInfoDoc({ ...doc });
    ModDelCateSate();
  };
  const handlePreview = async (doc) => {
    const documentFile = await UserService.getDocumentFirebase(
      doc.firebase_document_id
    );
    setPreviewDocumentModal(documentFile.document);
    
  };
  const OpenModalFirm = (doc) => {
    setFirmDoc({ ...doc });
    firmModal[1](true)
  };


  const downloadDocument = async (doc) => {
    try {
      const link = await UserService.getDocumentStorageBlob(
        doc.document_number
      );
      var downloadLink = document.createElement("a");
      downloadLink.href = link;
      downloadLink.download = doc.name + ".pdf";
      downloadLink.target = "_blank";
      
      refPage.current.appendChild(downloadLink);
      downloadLink.click();
      refPage.current.removeChild(downloadLink);
      downloadLink.remove();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <div ref={refPage} className="show_detail">
        <div id="divToPrint" className="table-responsive">
          <h2 className="mb-3 text-center">MIS DOCUMENTOS</h2>
          <hr />
          {!modalShow ? (
            state.length > 0 ? (
              <table className="table table-light table-striped">
                <thead id="dataTableHead">
                  <tr className="head-table">
                    <th scope="col">id</th>
                    <th scope="col">Nombre del documento</th>
                    <th scope="col">Estado</th>
                    <th scope="col">Fecha de Creación</th>
                    <th scope="col">Acción</th>
                  </tr>
                </thead>
                <tbody className="">
                  {state.map((doc, i) => {
                    return (
                      <tr key={i}>
                        <td>{doc.id}</td>
                        <td>{doc.name ? doc.name : "Sin Nombre"}</td>
                        <td>
                          {doc.state === "pagado"
                            ? "Pagado"
                            : doc.state === "necesita_firma"
                            ? "Falta firma"
                            : doc.state === "por_firmar"
                            ? "Firma"
                            : "Borrador"}
                        </td>
                        <td>
                          {doc.created_at
                            ? moment(doc.created_at).format("MMMM Do YYYY")
                            : "No tiene fecha de creación"}
                        </td>
                        {/* <td key={i} onClick={() => showAttachments(i, doc.id)}>
                          Mostrar adjuntos
                        </td> */}
                        {doc.state !== "pagado" &&
                        doc.state !== "necesita_firma" &&  doc.state !== "por_firmar" ? (
                          <td>
                            <i
                              className="fas fa-link pl-2 icon_option"
                              onClick={() => showAttachments(doc)}
                            ></i>
                            <i
                              className="far fa-eye pl-2 icon_option"
                              onClick={() => handlePreview(doc)}
                            ></i>

                            <i
                              className="far fa-edit pl-2 icon_option"
                              onClick={() =>
                                handleModal(doc, setData, setTitle, true)
                              }
                            ></i>
                            <i
                              className="far fa-trash-alt pl-2 icon_option"
                              onClick={(e) => OpenModalDelete(doc)}
                            ></i>
                          </td>
                        ) : (
                          <td>
                            <i
                              className="fas fa-link pl-2 icon_option"
                              onClick={() => showAttachments(doc)}
                            ></i>
                            <i
                              className="far fa-eye pl-2 icon_option"
                              onClick={() => handlePreview(doc)}
                            ></i>

                            {(doc.state === "pagado") ? (
                            <i
                              className="fas fa-file-pdf pl-2 icon_option"
                              onClick={() => downloadDocument(doc)}
                            ></i>
                            ) :  (
                              <i
                                className="fas fa-pen-nib pl-2 icon_option"
                                onClick={() => {OpenModalFirm(doc)}}
                              ></i>
                            )}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="container">
                <div className="body-style">
                  <div className="text__img__404">
                    <h1>!Lo sentimos!</h1>
                    <div className="text__4">No tienes Documentos creados</div>
                    <div>
                      <br />
                      <Button color="info" className="button-style">
                        Crear Nuevo Documento
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <div className="d-flex justify-content-center m-auto">
              {/* <Spinner animation="border" variant="primary" /> */}
            </div>
          )}
        </div>
      </div>
      <DeleteDoc
        showDelMod={showDelMod}
        setshowDelMod={setshowDelMod}
        title={`Eliminar Documento: ${infoDoc.name}`}
        message={"¿Estas seguro de eliminar el documento de seleccionado?"}
        refreshData={refreshData}
        setrefreshData={setrefreshData}
        ModAlertMod={ModAlertMod}
        setModalData={setModalData}
        isDocument
        idInfo={infoDoc.id}
        data={infoDoc}
      />
      <AttachmentsDoc
        showAttachMod={attachments}
        setShowAttachMod={setAttachments}
        files={listAttachments}
        setListAttachments={setListAttachments}
        selectDoc={selectDoc}
      />
      <PreviewDocument
        documentHook={[previewDocumentModal, setPreviewDocumentModal]}
        title="Previsualización"
      />
      <FirmDocumentModal
        showHook={firmModal}
        dataHook={[firmDoc, setFirmDoc]}
      />
    </>
  );
};

export default DocumentProfile;
